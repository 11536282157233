<template>
  <svg style="margin-left: 20px">
    <!--grid-->
    <line
      v-for="(n, i) in 12 * dates"
      v-bind:key="i + Math.random()"
      :x1="getLine(n)"
      y1="0"
      :x2="getLine(n)"
      y2="100"
      style="stroke: rgb(0, 0, 0); stroke-width: 1"
    />
    <GanttItem
      v-for="(chart, idx) in charts"
      v-bind:key="idx"
      :chart="chart"
      :startTime="chart.beginTime"
      :endTime="chart.endTime"
      :type="chart.workKind"
      :hyojiStart="hyojiStart"
      :hyojiEnd="hyojiEnd"
      :dates="dates"
      :rate="rate"
    />
    <!-- :tooltip="chart.tooltip" -->
  </svg>
</template>
<script>
export default {
  components: {
    GanttItem: () => import('@/plan/manualPlanning/gantt/GanttItem'),
  },
  name: 'GanttChart',
  props: {
    /**
     * Chart情報
     * @type {Array}
     * @requires
     */
    charts: {
      type: Array,
      required: true,
    },
    /**
     * 表示StartDate
     */
    hyojiStart: {
      type: String,
      default: '',
    },
    /**
     * 表示EndDate
     */
    hyojiEnd: {
      type: String,
      default: '',
    },
    /**
     * rate
     */
    rate: {
      type: Number,
      default: 1,
    },
    /**
     * date
     */
    dates: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      chart: this.chartParams(),
    }
  },
  methods: {
    /**
     * ganttChart情報
     * @return {Object}
     */
    chartParams() {
      return {
        start: null,
        end: null,
        type: null,
      }
    },
    /**
     * グリッド座標値
     */
    getLine(value) {
      var line = value * 120
      line = line * this.rate
      return line
    },
  },
}
</script>
